<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl" />
      <getecma-row class="mb--xxl mt--md flex-grow-1 d-flex">
        <getecma-col class="flex-grow-1 d-flex flex-column">
          <getecma-header>Configurações</getecma-header>
          <getecma-p size="md">
            Essa é a página onde você pode editar suas informações.
          </getecma-p>
          <getecma-form v-if="user" v-slot="{ validate }" :submit="updateUser" form-class="d-flex flex-column flex-grow-1"
            class="d-flex flex-grow-1 flex-column">
            <div>
              <getecma-row class="mt--xxl">
                <getecma-col :xs="24" :span="12">
                  <getecma-input
                    v-model="user.name"
                    label="Nome*"
                    type="text"
                    name="nome"
                    rules="required"
                    placeholder="ex.: Lucas" />
                </getecma-col>
              </getecma-row>
              <getecma-row class="mt--md">
                <getecma-col :xs="24" :span="12">
                  <getecma-input
                    v-model="user.email"
                    label="E-mail*"
                    type="email"
                    name="e-mail"
                    rules="required"
                    placeholder="ex.: lucas@email.com"
                    disabled />
                </getecma-col>
              </getecma-row>
              <getecma-divider class="mt--xxl" />
              <getecma-row class="mt--md">
                <getecma-col :xs="24" :span="12">
                  <label class="mb--md fs--lg fw--strong" for="password">Senha</label>
                  <getecma-input
                    id="password"
                    v-model="user.password"
                    type="password"
                    name="senha"
                    class="password"
                    autocomplete="off"
                    rules="min:8"
                    placeholder="********" />
                </getecma-col>
                <getecma-col :xs="24" :span="12">
                  <label class="mb--md fs--lg fw--strong" for="confirm_password">Confirmar senha</label>
                  <getecma-input
                    id="confirm_password"
                    v-model="user.password2"
                    type="password"
                    name="confirmação de senha"
                    class="password"
                    autocomplete="off"
                    rules="min:8"
                    placeholder="********" />
                </getecma-col>
              </getecma-row>
              <getecma-small class="d-flex mt--lg text-success" align="left">*Sua senha deve possuir pelo menos 8 caracteres</getecma-small>
              <getecma-small class="d-flex text-success" align="left">*Sua senha deve possuir números e caracteres especiais</getecma-small>
            </div>
            <div class="mt--xl mb--md d--flex justify-content-end">
                <getecma-button
                  class="fs--sm"
                  size="xl"
                  :round="false"
                  @click="goToBasePage()">
                  Cancelar
                </getecma-button>
                <getecma-button
                  native-type="submit"
                  class="ms--md fs--sm"
                  :round="false"
                  bg-color="success"
                  size="xl"
                  @click="validate">
                  Salvar
                </getecma-button>
              </div>
          </getecma-form>
        </getecma-col>
      </getecma-row>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { getters } from '@/modules/user/user.store';
import { toastError, toastSuccess } from '@/services/toastService';
import { goToBasePage } from '@/router/route.service';
import { isMinLayout } from '@/helpers/layout/layout';
import { goToOpenUser } from '@/modules/user/user.routes';
import { updateUserInformation } from '@/modules/user/user.service';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaSettingsPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Configurações', path: this.$route.path },
      ],
      user: null,
      fullscreenLoading: false,
    };
  },
  mounted() {
    this.fetchUserInfo();
  },
  methods: {
    isMinLayout,
    goToBasePage,
    fetchUserInfo() {
      this.user = { ...getters.getUser() };
      this.user.password = null;
    },
    updateUser() {
      if (this.user.password) {
        const specialChars = !!(this.user.password.match(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]+/) && this.user.password.match(/\d+/));
        if (!specialChars) {
          toastError('Sua senha deve possuir números e caracteres especiais!');
          return false;
        }
      }

      if (this.user.password && this.user.password !== this.user.password2) {
        toastError('As senhas não coincidem!');
      } else {
        this.fullscreenLoading = true;
        updateUserInformation(this.user)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Usuário salvo!');
            goToOpenUser(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o perfil do usuário');
            this.fullscreenLoading = false;
          });
      }
      return true;
    },
  },
};
</script>
